import { type ChangeEventHandler, type FC, type PropsWithChildren, useCallback, useMemo } from 'react';

import {
  Button,
  GridContainer,
  GridItem,
  Headline,
  Icon,
  SpinningIcon,
  Switch,
  Text,
  Tooltip,
  styled,
} from '@cofenster/web-components';

import { usePollingVideoAsset } from '../../../../api/hooks/videoAsset/usePollingVideoAsset';

import { AudioBox } from './AudioBox';
import { useAudioOverlayContext } from './Context';

const CallToActionButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.brand.grey100}`,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
}));

const ErrorText = styled(Text)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const StyledSwitch = styled(Switch)(() => ({
  width: '100%',
}));

const EnhanceAudioContainer: FC<PropsWithChildren> = ({ children, ...rest }) => (
  <GridContainer mt={2} {...rest}>
    <GridItem xs={12}>
      <Headline variant="h6" component="p">
        i18n.projectEdit.EnhancedAudio.title
      </Headline>
    </GridItem>
    <GridItem xs={12} mt={-1}>
      {children}
    </GridItem>
  </GridContainer>
);

export const EnhanceAudio: FC = () => {
  const { videoSceneAsset, isEnhanced, setIsEnhanced } = useAudioOverlayContext();
  const sceneVideo = videoSceneAsset?.asset ?? undefined;

  // Poll the video asset to know when it’s done processing, and use that
  // status to update the UI (loading, success, error…)
  const videoAsset = usePollingVideoAsset(sceneVideo?.videoAsset?.id);

  const isVideoAssetReady = videoAsset?.status === 'Ready';

  const enhanceAudio = useCallback(() => {
    setIsEnhanced(true);
  }, [setIsEnhanced]);

  // This synchronizes the parent state with the state of the HTML checkbox
  // element
  const updateIsEnhanced: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setIsEnhanced(event.target.checked),
    [setIsEnhanced]
  );

  const enhanceAudioToggle = useMemo(
    () => (
      <AudioBox>
        <Icon type="MagicWandIcon" size="m" />
        <StyledSwitch
          data-testid="enhanced-audio-switch-scene"
          id="enhance"
          checked={isEnhanced}
          onChange={updateIsEnhanced}
          switchPosition="right"
          isBlock
          disabled={!isVideoAssetReady}
        >
          <Text variant="m">i18n.projectEdit.EnhancedAudio.remove</Text>
        </StyledSwitch>
      </AudioBox>
    ),
    [isEnhanced, isVideoAssetReady, updateIsEnhanced]
  );

  if (!isEnhanced && !isVideoAssetReady) {
    return (
      <EnhanceAudioContainer>
        <Tooltip title="i18n.projectEdit.EnhancedAudio.mainVideoAssetNotReady">{enhanceAudioToggle}</Tooltip>
      </EnhanceAudioContainer>
    );
  }

  if (videoAsset?.status === 'Error') {
    return (
      <EnhanceAudioContainer>
        <CallToActionButton
          data-testid="audio-enhancement-error-button"
          variant="tertiary"
          onClick={enhanceAudio}
          startIcon={<Icon type="MagicWandIcon" size="m" />}
          disabled={!sceneVideo?.id}
          fullWidth
          aria-describedby="enhanced-audio-error"
        >
          i18n.projectEdit.EnhancedAudio.remove
        </CallToActionButton>
        <ErrorText variant="m" color="negative" component="p" id="enhanced-audio-error">
          i18n.projectEdit.EnhancedAudio.error
        </ErrorText>
      </EnhanceAudioContainer>
    );
  }

  if (videoAsset?.status === 'Waiting' || videoAsset?.status === 'Processing') {
    return (
      <EnhanceAudioContainer data-testid="enhanced-audio-processing">
        <AudioBox>
          <SpinningIcon speed="fast" type="LoadingIcon" size="m" color="blue" />
          <Text component="p">i18n.projectEdit.EnhancedAudio.removing</Text>
        </AudioBox>
      </EnhanceAudioContainer>
    );
  }

  if (videoAsset?.videoUrl) {
    return <EnhanceAudioContainer>{enhanceAudioToggle}</EnhanceAudioContainer>;
  }
};

import { useCallback } from 'react';
import { useChoiceDialog } from '../../../hooks/useChoiceDialog';

// this assumes you have permission to download and update
export const useWarnAboutPendingChangesDialog = (
  hasPendingChanges: boolean,
  download: () => unknown,
  update: () => unknown
) => {
  const pickUpdateOrDownload = useChoiceDialog({
    title: 'i18n.dialogs.projectVideoPendingChangesDownloadDialog.headline',
    content: 'i18n.dialogs.projectVideoPendingChangesDownloadDialog.subline',
    left: 'i18n.dialogs.projectVideoPendingChangesDownloadDialog.update',
    leftValue: 'update',
    right: 'i18n.dialogs.projectVideoPendingChangesDownloadDialog.download',
    rightValue: 'download',
  });

  return useCallback(async () => {
    if (!hasPendingChanges) {
      await download();
      return;
    }

    const choice = await pickUpdateOrDownload();
    if (choice === 'update') {
      await update();
    } else if (choice === 'download') {
      await download();
    }
  }, [hasPendingChanges, update, download, pickUpdateOrDownload]);
};

import {
  type Dispatch,
  type FC,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

import { type Scene, useScenes } from '../../../../contexts/scenes/useScenes';
import { isAudioSceneAsset, isMainSceneAsset, isVideoSceneAsset } from '../../../../helpers/sceneAssets/is';
import type { AudioSceneAsset, VideoSceneAsset } from '../../../../helpers/sceneAssets/types';

export type AudioOverlayStatus = 'UPLOADED_AUDIO' | 'MUTED_AUDIO' | 'SCENE_AUDIO';

export const AudioOverlayContext = createContext<{
  savedStatus: AudioOverlayStatus;

  localStatus: AudioOverlayStatus;
  setLocalStatus: Dispatch<SetStateAction<AudioOverlayStatus>>;

  wasEnhanced: boolean;
  isEnhanced: boolean;
  setIsEnhanced: Dispatch<SetStateAction<boolean>>;

  audioSceneAsset?: AudioSceneAsset;
  videoSceneAsset?: VideoSceneAsset;

  sceneId?: string;
}>({
  // These values are incorrect but it doesn’t matter as the provider will
  // define the proper values
  savedStatus: 'SCENE_AUDIO',
  localStatus: 'SCENE_AUDIO',
  setLocalStatus: () => {},
  wasEnhanced: false,
  isEnhanced: false,
  setIsEnhanced: () => {},
});

const getStatusFromVolumes = (audioSceneAssetVolume?: number, videoSceneAssetVolume?: number): AudioOverlayStatus => {
  if (Number(audioSceneAssetVolume) > 0) return 'UPLOADED_AUDIO';
  if (Number(videoSceneAssetVolume) > 0) return 'SCENE_AUDIO';
  return 'MUTED_AUDIO';
};

const useCurrentSceneAssets = (currentScene?: Scene) => {
  const audioSceneAsset = currentScene?.sceneAssets.find(isAudioSceneAsset);
  const videoSceneAsset = currentScene?.sceneAssets.filter(isMainSceneAsset).find(isVideoSceneAsset);

  return useMemo(() => ({ audioSceneAsset, videoSceneAsset }), [audioSceneAsset, videoSceneAsset]);
};

const useSavedStatus = ({
  audioSceneAsset,
  videoSceneAsset,
}: { audioSceneAsset?: AudioSceneAsset; videoSceneAsset?: VideoSceneAsset }) => {
  const audioVolume = audioSceneAsset?.asset?.volume;
  const videoVolume = videoSceneAsset?.asset?.volume;

  return useMemo(() => getStatusFromVolumes(audioVolume, videoVolume), [audioVolume, videoVolume]);
};

export const AudioOverlayContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { currentScene } = useScenes();
  const { audioSceneAsset, videoSceneAsset } = useCurrentSceneAssets(currentScene);
  const savedStatus = useSavedStatus({ audioSceneAsset, videoSceneAsset });
  const [localStatus, setLocalStatus] = useState<AudioOverlayStatus>(savedStatus);
  const wasEnhanced = useMemo(
    () => videoSceneAsset?.asset?.videoAsset?.variations.includes('EnhancedAudio') ?? false,
    [videoSceneAsset?.asset?.videoAsset]
  );
  const [isEnhanced, setIsEnhanced] = useState(wasEnhanced);

  const context = useMemo(
    () => ({
      sceneId: currentScene?.id,
      audioSceneAsset,
      videoSceneAsset,
      savedStatus,
      localStatus,
      setLocalStatus,
      wasEnhanced,
      isEnhanced,
      setIsEnhanced,
    }),
    [currentScene, localStatus, audioSceneAsset, videoSceneAsset, savedStatus, wasEnhanced, isEnhanced]
  );

  return <AudioOverlayContext.Provider value={context}>{children}</AudioOverlayContext.Provider>;
};

export const useAudioOverlayContext = () => useContext(AudioOverlayContext);

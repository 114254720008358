import { Headline, Spacing, styled } from '@cofenster/web-components';
import type { FC } from 'react';

import { FeatureFlagRestriction } from '../../../../components/featureFlag/FeatureFlagRestriction';

import { AudioOverlayAudioUploader } from './AudioUploader';
import { useAudioOverlayContext } from './Context';
import { EnhanceAudio } from './EnhanceAudio';
import { MuteSwitch } from './MuteSwitch';
import { PollingAudioAsset } from './PollingAudioAsset';
import { AudioOverlayPreview } from './Preview';
import { AudioOverlayStatusSelect } from './StatusSelect';

const PanelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
}));

const FlexContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(4),
}));

const UploaderWrapper = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  marginTop: theme.spacing(-2),
}));

export const AudioOverlayPanelContent: FC = () => {
  const { localStatus, videoSceneAsset, audioSceneAsset, sceneId } = useAudioOverlayContext();
  const videoAsset = videoSceneAsset?.asset?.videoAsset;
  const audioAsset = audioSceneAsset?.asset?.audioAsset;
  const previewUrl = videoAsset?.audioUrl || videoAsset?.videoUrl;

  return (
    <PanelWrapper data-testid="audio-panel">
      <MuteSwitch />

      {localStatus !== 'MUTED_AUDIO' && (
        <FlexContainer>
          <Spacing bottom={2}>
            <Headline variant="h6" component="h3">
              i18n.projectEdit.SceneAudioPanelContent.title
            </Headline>
          </Spacing>

          <AudioOverlayStatusSelect />

          {localStatus === 'SCENE_AUDIO' && (
            <>
              {previewUrl && <AudioOverlayPreview src={previewUrl} />}
              <FeatureFlagRestriction has="BACKGROUND_NOISE_REMOVAL">
                <EnhanceAudio />
              </FeatureFlagRestriction>
            </>
          )}

          {localStatus === 'UPLOADED_AUDIO' && sceneId && (
            <UploaderWrapper data-testid="scene-audio-dropzone">
              <PollingAudioAsset audioAssetId={audioAsset?.id}>
                {(audioAsset) => (
                  <AudioOverlayAudioUploader sceneId={sceneId} sceneAsset={audioSceneAsset} audioAsset={audioAsset} />
                )}
              </PollingAudioAsset>
            </UploaderWrapper>
          )}
        </FlexContainer>
      )}
    </PanelWrapper>
  );
};

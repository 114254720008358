import { useCallback } from 'react';

import type { SceneVideo } from '../../../../../api/hooks/scene/useScenes';
import { useUpdateSceneVideo } from '../../../../../api/hooks/sceneVideo/useUpdateSceneVideo';
import { useWebManagerTracking } from '../../../../../hooks/useWebManagerTracking';

export const useUpdatePlaybackRate = (sceneId: string, sceneVideo: SceneVideo | null) => {
  const updateSceneVideo = useUpdateSceneVideo();
  const { trackEvent } = useWebManagerTracking();

  return useCallback(
    async (playbackRate: number) => {
      if (!sceneVideo) return;
      await updateSceneVideo(sceneVideo.id, { playbackRate });
      trackEvent({
        event: 'playbackRateUpdated',
        details: { playbackRate, sceneId },
      });
    },
    [sceneVideo, updateSceneVideo, trackEvent, sceneId]
  );
};

import type { ContributionConfigurationTab } from './pages/ProjectContributionConfiguration/ProjectContributionConfigurationContent/types';

export type TeamRouteParams = { teamId: string };
export type ProjectFolderRouteParams = { folderId: string };
export type ProjectCreateRouteParams = { folderId: string };
export type ProjectRouteParams = { projectId: string; subtitlesLanguage: string | undefined };
export type ProjectSceneRouteParams = ProjectRouteParams & { sceneId: string };
export type ProjectEditRouteParams = ProjectRouteParams & { sceneId?: string };
export type ProjectEditorRouteParams = ProjectRouteParams & { sceneId?: string };
export type ProjectEditSubtitleParams = ProjectSceneRouteParams & { subtitleId: string };
export type ProjectSceneSubtitleEditParams = ProjectSceneRouteParams & { language: string };
export type BrandingThemesRouteParams = { themeId?: string };
export type ResetPasswordRouteParams = { token: string };
export type AccountUserUpdateRouteParams = { userId: string };
export type AccountMusicUpdateRouteParams = { musicId: string };
export type ConsentsProjectParams = { projectId: string };
export type ProjectContributionsConfigurationRouteParams = ProjectRouteParams & {
  type: ContributionConfigurationTab;
};
export type RecordVideoBriefingRouteParams = ProjectRouteParams & { contributionRequestListId: string };
export type SetPasswordRouteParams = ResetPasswordRouteParams;
export type MediaLibraryRouteParams = { mediaLibraryItemId?: string };

export const routes = {
  signin: '/signin',
  forgotPassword: '/forgot-password',
  resetSent: '/reset-sent',
  deactivatedUser: '/deactivated',

  resetPassword: '/reset-password/:token?',
  setPassword: '/set-password/:token?',

  signInError: '/signin-error',
  applied: '/applied',

  home: '/',

  projectTemplates: '/project-templates',
  projectTemplateCreate: '/project-templates/create',

  projectFolder: '/projects/:folderId',
  projectCreate: '/projects/:folderId/create',
  teamSettings: '/team/:teamId/settings',
  teamMembers: '/team/:teamId/members',

  project: '/project/:projectId',

  projectContributions: '/project/:projectId/contributions',
  projectEdit: '/project/:projectId/edit',
  projectEditScene: '/project/:projectId/edit/:sceneId',

  projectContributionsConfiguration: '/project/:projectId/contributions/configuration/:type',
  projectCutScene: '/project/:projectId/edit/:sceneId/cut',
  projectSceneOverlays: '/project/:projectId/edit/:sceneId/overlays',
  projectManipulateAsset: '/project/:projectId/edit/:sceneId/manipulate-asset',
  projectCaptureSceneAsset: '/project/:projectId/edit/:sceneId/capture-assets',
  projectSceneSubtitle: '/project/:projectId/edit/:sceneId/subtitle',
  projectSceneSubtitleEdit: '/project/:projectId/edit/:sceneId/subtitle/:language',
  projectSceneSubtitleCreate: '/project/:projectId/edit/:sceneId/subtitle/create',

  projectDesign: '/project/:projectId/design',
  projectMusic: '/project/:projectId/music',
  projectVideo: '/project/:projectId/video',
  projectVideoSubtitles: '/project/:projectId/video/:subtitlesLanguage',
  projectSubtitles: '/project/:projectId/subtitles',
  projectEditSubtitle: '/project/:projectId/subtitle/:subtitleId/edit',
  projectCreateTranscription: '/project/:projectId/transcription',
  projectCreateTranslation: '/project/:projectId/translation',

  recordVideoBriefing: '/project/:projectId/list/:contributionRequestListId/record-video-briefing',

  settings: '/settings',
  settingsPersonal: '/settings/personal',
  settingsNotifications: '/settings/notifications',
  settingsAccount: '/settings/account',
  settingsAccountMembers: '/settings/account/members',
  settingsAccountMemberCreate: '/settings/account/members/create',
  settingsAccountMemberUpdate: '/settings/account/members/update/:userId',
  settingsAccountTeams: '/settings/account/teams',
  settingsAccountTeamMembers: '/settings/account/team/:teamId/members',
  settingsAccountConsents: '/settings/account/consents',
  settingsAccountProjectConsents: '/settings/account/consents/:projectId',

  brandingThemes: '/branding/themes',
  brandingTheme: '/branding/themes/:themeId',
  brandingMediaLibrary: '/branding/media-library/:mediaLibraryItemId?',

  brandingMusics: '/branding/musics',
  brandingMusicCreate: '/branding/musics/create',
  brandingMusicUpdate: '/branding/musics/update/:musicId',

  // Routes for improve dev
  projectRenderDescription: '/project/:projectId/render-description',
  demo: '/demo',
} as const;

export type Routes = typeof routes;
export type RouteName = keyof Routes;

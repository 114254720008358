import { styled } from '@mui/material';
import type { CSSProperties, ChangeEventHandler, FC, PropsWithChildren } from 'react';

import { preventForwardProps } from '../../../utilities';
import { Icon } from '../../assets/icons/Icon';

// 1. Vertically center the toggle and the label. `flex` could be used if a
//    block-level display is preferred.
// 2. Grant a position context for the visually hidden and absolutely positioned
//    input.
// 3. Provide spacing between the toggle and the text regardless of layout
//    switchPosition.
// 4. Make it possible to have the label on the left side of the toggle.
const Label = styled('label')(() => ({
  display: 'var(--display, flex)', // 1
  alignItems: 'center', // 1
  position: 'relative', // 2
  gap: '1ch', // 3
  flexDirection: 'var(--direction, row)' as 'row' | 'row-reverse', // 4
}));

// 1. Vertically center the icons and space them evenly in the available
//    horizontal space, essentially giving something like: [ ✔ ✗ ]
// 2. Make sure the toggle cannot shrink passed its minimum size as it becomes
//    unusable.
// 3. Size the display according to the size of the handle.
// 4. For the toggle to be visible in Windows High-Contrast Mode, we apply a
//    thin semi-transparent (or fully transparent) border.
//    See: https://twitter.com/aardrian/status/1379786724222631938?s=20
// 5. Grant a position context for the handle.
// 6. Give a pill-like shape with rounded corners, regardless of the size.
const ToggleDisplay = styled('span')(({ theme }) => ({
  '--offset': '5px',
  '--diameter': '20px',

  display: 'inline-flex', // 1
  alignItems: 'center', // 1
  justifyContent: 'space-around', // 1

  flexShrink: 0, // 2
  width: 'calc(var(--diameter) * 2 + var(--offset) * 2 + 2px)', // 3
  height: 'calc(var(--diameter) + var(--offset) * 2 + 2px)', // 3

  border: '1px solid rgb(0 0 0 / 0.1)', // 4

  position: 'relative', // 5
  borderRadius: '100vw', // 6
  backgroundColor: theme.palette.brand.grey50,

  transition: '250ms, outline-offset 0ms, outline 0ms',
  cursor: 'pointer',

  '@media (prefers-reduced-motion: reduce)': {
    transitionDuration: '0ms',
  },

  // 1. When the toggle is interacted with with a mouse click (and therefore
  //    the focus does not have to be ‘visible’ as per browsers heuristics),
  //    remove the focus outline. This is the native checkbox’s behaviour where
  //    the focus is not visible when clicking it.
  'input:focus-visible + &': theme.mixins.focusRing, // 1

  // 1. When the input is checked, change the display background color.
  'input:checked + &': {
    backgroundColor: theme.palette.brand.blue, // 1
  },

  // 1. When the input is disabled, tweak the toggle styles so it looks dimmed
  //    with less sharp colors, softer opacity and a relevant cursor.
  'input:disabled + &': {
    opacity: 0.6, // 1
    filter: 'grayscale(40%)', // 1
    cursor: 'not-allowed', // 1
  },
}));

// 1. Size the round handle according to the diameter custom property.
// 2. For the handle to be visible in Windows High-Contrast Mode, we apply a
//    thin semi-transparent (or fully transparent) border.
//    See: https://twitter.com/aardrian/status/1379786724222631938?s=20
// 3. Absolutely position the handle, offset by the spacing amount on the left.
// 4. Absolutely center the icon within the handle.
const ToggleHandle = styled('span')(({ theme }) => ({
  width: 'var(--diameter)', // 1
  height: 'var(--diameter)', // 1
  borderRadius: '50%', // 1

  border: '1px solid transparent', // 2

  position: 'absolute', // 3
  left: 'var(--offset)', // 3

  backgroundColor: theme.palette.brand.grey300,
  transition: 'inherit',

  display: 'flex', // 4
  justifyContent: 'center', // 4
  alignItems: 'center', // 4

  // 1. When the input is checked, slide the handle to the right, slightly
  //    increase its size, and brighten its color.
  'input:checked + * > &': {
    backgroundColor: theme.palette.brand.white,
    transform: 'translateX(100%) scale(1.2)', // 1
  },

  // 1. When the handle is *not* checked, make sure the icon is not visible.
  'input:not(:checked) + * > & > *': {
    opacity: 0, // 1
  },
}));

const Input = styled('input')(() => ({
  position: 'absolute',
  opacity: 0,
  pointerEvents: 'none',
}));

const VisualLabel = styled(
  'span',
  preventForwardProps(['disabled'])
)<{ disabled?: boolean }>(({ disabled }) => ({
  flexGrow: 1,
  cursor: disabled ? undefined : 'pointer',
}));

export type SwitchProps = {
  id: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  checked?: boolean;
  switchPosition?: 'left' | 'right';
  isBlock?: boolean;
  'data-testid'?: string;
};

export const Switch: FC<PropsWithChildren<SwitchProps>> = (props) => {
  return (
    <Label
      className={props.className}
      htmlFor={props.id}
      style={
        {
          '--direction': props.switchPosition === 'right' ? 'row-reverse' : 'row',
          '--display': props.isBlock ? 'flex' : 'inline-flex',
        } as CSSProperties
      }
      data-testid={props['data-testid']}
    >
      <Input
        type="checkbox"
        name={props.name ?? props.id}
        id={props.id}
        onChange={props.onChange as ChangeEventHandler<HTMLInputElement>}
        disabled={props.disabled}
        checked={props.checked}
      />
      <ToggleDisplay hidden>
        <ToggleHandle>
          <Icon type="CheckIcon" size="s" />
        </ToggleHandle>
      </ToggleDisplay>
      <VisualLabel disabled={props.disabled}>{props.children}</VisualLabel>
    </Label>
  );
};

Switch.displayName = 'Switch';

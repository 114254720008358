import type { SceneVideo } from './types';

export const getSceneVideoDuration = (sceneVideo: SceneVideo) => {
  if (!sceneVideo.videoAsset?.duration) return;
  if (sceneVideo.videoSections.length === 0) return sceneVideo.videoAsset.duration / sceneVideo.playbackRate;
  return (
    sceneVideo.videoSections.reduce((acc, videoSection) => acc + (videoSection.offsetOut - videoSection.offsetIn), 0) /
    sceneVideo.playbackRate
  );
};

import { type FC, type MouseEventHandler, useEffect, useMemo, useState } from 'react';

import { Button, Headline, Icon, NumericInput, styled, usePopover, withPopover } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';

const ColContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const PopoverContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

const useFormattedTime = (seconds = 0) => {
  return `${seconds.toFixed(1)}s`;
};

const DurationButton: FC<{ duration: number; onClick?: MouseEventHandler<HTMLButtonElement>; disabled?: boolean }> = ({
  duration,
  onClick,
  disabled,
}) => (
  <Button variant="tertiary" disabled={disabled} startIcon={<Icon type="ClockIcon" size="s" />} onClick={onClick}>
    {useFormattedTime(duration)}
  </Button>
);

const DurationPopover: FC<{ duration: number; onChange: (value: number) => void }> = ({
  duration: initialDuration,
  onChange,
}) => {
  const [duration, setDuration] = useState(initialDuration);
  const { isOpen } = usePopover();

  useEffect(() => {
    if (isOpen || initialDuration === duration) return;
    onChange(duration);
  }, [initialDuration, duration, isOpen, onChange]);

  return (
    <PopoverContainer>
      <ColContainer>
        <Headline variant="h5" component="p">
          i18n.projectEdit.durationInput.label
        </Headline>
        <NumericInput
          value={duration}
          min={1}
          unit="s"
          onChange={setDuration}
          decreaseLabel="i18n.projectEdit.durationInput.decreaseLabel"
          increaseLabel="i18n.projectEdit.durationInput.increaseLabel"
        />
      </ColContainer>
    </PopoverContainer>
  );
};

export const SceneDurationButton: FC<{ duration: number; onChange: (value: number) => void; disabled?: boolean }> = ({
  duration,
  onChange,
  disabled,
}) => {
  const { pause } = useEditorPlayer();
  const DurationButtonWithPopover = useMemo(
    () =>
      withPopover(DurationButton, {
        anchorOriginX: 'center',
        anchorOriginY: 'top',
        transformOriginX: 'center',
        transformOriginY: 'bottom',
        offsetY: -20,
        children: <DurationPopover duration={duration} onChange={onChange} />,
      }),
    [duration, onChange]
  );

  return <DurationButtonWithPopover duration={duration} disabled={disabled} onClick={pause} />;
};

import type { SceneAsset } from '../../api/hooks/scene/useScenes';
import { isSceneVideo } from '../../helpers/sceneAssets/is';

export type SceneAssetProcessingType = ReturnType<typeof useSceneAssetProcessingType>;

export const useSceneAssetProcessingType = (asset: SceneAsset['asset']) => {
  if (!asset || !isSceneVideo(asset) || !asset.videoAsset) return;
  if (['Error', 'Ready'].includes(asset.videoAsset.status)) return;
  if (asset.videoAsset.variations.length === 0) return 'Transcoding';
  if (asset.videoAsset.variations[0] === 'EnhancedAudio') return 'EnhanceAudio';
  if (asset.videoAsset.variations[0] === 'VirtualBackground') return 'VirtualBackground';
};

import { useCallback, useMemo, useState } from 'react';

import { useUpdateSceneAudio } from '../../../../api/hooks/sceneAudio/useUpdateSceneAudio';
import { useUpdateSceneVideo } from '../../../../api/hooks/sceneVideo/useUpdateSceneVideo';
import { useScenes } from '../../../../contexts/scenes/useScenes';
import { useWebManagerTracking } from '../../../../hooks/useWebManagerTracking';

import { useAudioOverlayContext } from './Context';

const useTrackSave = () => {
  const tracking = useWebManagerTracking();
  const { currentScene } = useScenes();
  const { localStatus, isEnhanced } = useAudioOverlayContext();
  const remappedStatus = {
    UPLOADED_AUDIO: 'overlay',
    SCENE_AUDIO: 'scene',
    MUTED_AUDIO: 'muted',
  }[localStatus];

  return useCallback(
    () =>
      tracking.trackEvent({
        event: 'sceneAudioUpdated',
        details: {
          audioSelected: remappedStatus,
          sceneType: currentScene?.type,
          sceneId: currentScene?.id,
          textElementsCount: currentScene?.sceneTexts.length,
          audioEnhanced: isEnhanced && localStatus === 'SCENE_AUDIO',
        },
      }),
    [tracking, localStatus, remappedStatus, currentScene, isEnhanced]
  );
};

const useSaveData = () => {
  const updateSceneAudio = useUpdateSceneAudio();
  const updateSceneVideo = useUpdateSceneVideo();
  const { localStatus, audioSceneAsset, videoSceneAsset, wasEnhanced, isEnhanced } = useAudioOverlayContext();
  const audioAssetId = audioSceneAsset?.asset?.id;
  const videoAssetId = videoSceneAsset?.asset?.id;

  return useCallback(async () => {
    const enhancedAudioEnabled = isEnhanced === wasEnhanced ? undefined : isEnhanced;

    if (localStatus === 'MUTED_AUDIO') {
      if (audioAssetId) await updateSceneAudio(audioAssetId, { volume: 0 });
      if (videoAssetId) await updateSceneVideo(videoAssetId, { volume: 0, enhancedAudioEnabled });
    } else if (localStatus === 'SCENE_AUDIO') {
      if (audioAssetId) await updateSceneAudio(audioAssetId, { volume: 0 });
      if (videoAssetId) await updateSceneVideo(videoAssetId, { volume: 1, enhancedAudioEnabled });
    } else if (localStatus === 'UPLOADED_AUDIO' && audioAssetId) {
      await updateSceneAudio(audioAssetId, { volume: 1 });
      if (videoAssetId) await updateSceneVideo(videoAssetId, { volume: 0, enhancedAudioEnabled });
    }
  }, [localStatus, audioAssetId, videoAssetId, updateSceneAudio, updateSceneVideo, wasEnhanced, isEnhanced]);
};

export const useSaveAudioOverlay = () => {
  const [isSaving, setIsSaving] = useState(false);

  const trackSaveData = useTrackSave();
  const saveData = useSaveData();

  const handleSave = useCallback(async () => {
    if (isSaving) return;
    setIsSaving(true);

    try {
      await saveData();
      trackSaveData();
    } finally {
      setIsSaving(false);
    }
  }, [saveData, trackSaveData, isSaving]);

  return useMemo(() => [handleSave, isSaving] as const, [handleSave, isSaving]);
};

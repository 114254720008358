import { type FC, type MouseEventHandler, useEffect, useMemo, useState } from 'react';

import { Button, Chip, Headline, Icon, NumericInput, styled, usePopover, withPopover } from '@cofenster/web-components';

import { useEditorPlayer } from '../../../../contexts/editorPlayer/useEditorPlayer';

const ColContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const PopoverContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
}));

const Chips = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  justifyContent: 'center',
}));

const PlaybackRateButton: FC<{
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}> = ({ onClick, disabled }) => (
  <Button
    variant="tertiary"
    disabled={disabled}
    startIcon={<Icon type="GaugeIcon" size="s" />}
    onClick={onClick}
    data-testid="scene-playback-rate-button"
  >
    i18n.projectEdit.playbackRate.label
  </Button>
);

const PRESET_VALUES = [0.5, 1, 2, 4];

const PlaybackRatePopover: FC<{ playbackRate: number; onChange: (value: number) => void }> = ({
  playbackRate: initialPlaybackRate,
  onChange,
}) => {
  const [playbackRate, setPlaybackRate] = useState(initialPlaybackRate);
  const { isOpen } = usePopover();

  useEffect(() => {
    if (isOpen || initialPlaybackRate === playbackRate) return;
    onChange(playbackRate);
  }, [initialPlaybackRate, playbackRate, isOpen, onChange]);

  return (
    <PopoverContainer>
      <ColContainer>
        <Headline variant="h5" component="p">
          i18n.projectEdit.playbackRate.label
        </Headline>
        <NumericInput
          value={playbackRate}
          min={0.2}
          max={4}
          step={0.1}
          clickStep={0.5}
          unit="x"
          onChange={setPlaybackRate}
          decreaseLabel="i18n.projectEdit.playbackRate.decreaseLabel"
          increaseLabel="i18n.projectEdit.playbackRate.increaseLabel"
        />
        <Chips>
          {PRESET_VALUES.map((presetValue) => (
            <Chip
              key={presetValue}
              color={presetValue === playbackRate ? 'dark' : 'light'}
              clickable
              onClick={() => setPlaybackRate(presetValue)}
            >
              {presetValue}x
            </Chip>
          ))}
        </Chips>
      </ColContainer>
    </PopoverContainer>
  );
};

export const SceneVideoPlaybackRateButton: FC<{
  playbackRate: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}> = ({ playbackRate, onChange, disabled }) => {
  const { pause } = useEditorPlayer();
  const PlaybackRateButtonWithPopover = useMemo(
    () =>
      withPopover(PlaybackRateButton, {
        anchorOriginX: 'center',
        anchorOriginY: 'top',
        transformOriginX: 'center',
        transformOriginY: 'bottom',
        offsetY: -20,
        children: <PlaybackRatePopover playbackRate={playbackRate} onChange={onChange} />,
      }),
    [playbackRate, onChange]
  );

  return <PlaybackRateButtonWithPopover disabled={disabled} onClick={pause} />;
};

import {
  type FC,
  type KeyboardEvent,
  type MouseEvent,
  type MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import {
  PopoverMenuTriggerIcon,
  VisuallyHidden,
  isElementInViewport,
  styled,
  useDraggableStyles,
  useKeypress,
  useSortable,
  withPopoverMenu,
} from '@cofenster/web-components';
import { getAssetFromSceneAsset } from '../../../../../components/scene/helpers';
import type { Scene } from '../../../../../contexts/scenes/useScenes';
import { useTeamPermissionStatus } from '../../../../../contexts/user/TeamPermissionRestriction';
import { useSceneColorActionsPopoverContent } from '../hooks/useSceneColorActionsPopoverContent';
import { useSceneImageActionsPopoverContent } from '../hooks/useSceneImageActionsPopoverContent';
import { useSceneVideoActionsPopoverContent } from '../hooks/useSceneVideoActionsPopoverContent';

import {
  isColorSceneAsset,
  isImageSceneAsset,
  isMainSceneAsset,
  isVideoSceneAsset,
} from '../../../../../helpers/sceneAssets/is';
import { useCopySceneToClipboard } from '../../../hooks/useCopySceneToClipboard';
import { DraggableEditorThumbnail } from './DraggableEditorThumbnail';
import { DraggableEditorThumbnailButton } from './DraggableEditorThumbnail/Button';
import { ThumbnailContainer } from './ThumbnailContainer';
import { ThumbnailListItemWrapper } from './ThumbnailListItemWrapper';

const useScrollToThumbnail = (node: HTMLElement | null, isSelected: boolean) => {
  const TOP_PANEL_HEIGHT = 96;

  useEffect(() => {
    if (!node || !isSelected || isElementInViewport(node, TOP_PANEL_HEIGHT)) return;
    node.scrollIntoView(false);
  }, [isSelected, node]);
};

const usePopoverActions = (scene: Scene) => {
  const sceneAsset = scene.sceneAssets.find(isMainSceneAsset);

  const colorActions = useSceneColorActionsPopoverContent({
    scene,
    colorSceneAsset: sceneAsset && isColorSceneAsset(sceneAsset) ? sceneAsset : undefined,
  });
  const imageActions = useSceneImageActionsPopoverContent({
    scene,
    imageSceneAsset: sceneAsset && isImageSceneAsset(sceneAsset) ? sceneAsset : undefined,
  });
  const videoActions = useSceneVideoActionsPopoverContent({
    scene,
    videoSceneAsset: sceneAsset && isVideoSceneAsset(sceneAsset) ? sceneAsset : undefined,
  });

  if (scene.type === 'video' || scene.type === 'screenRecording') return videoActions;
  if (scene.type === 'image') return imageActions;
  if (scene.type === 'color') return colorActions;
  return [];
};

export const ActionsContainer = styled('div')(({ theme }) => ({
  zIndex: theme.zIndex.above,
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),

  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  '> *': {
    transition: '200ms opacity ease-out',

    '&:not([aria-pressed="true"]):not(:focus)': {
      opacity: 0,
    },

    '.editorThumbnail:hover &, .editorThumbnail:focus-within &': {
      opacity: 1,
    },
  },
}));

type ThumbnailProps = {
  scene: Scene;
  canReorder: boolean;
  isCurrent: boolean;
  onSelect?: (event?: MouseEvent | KeyboardEvent) => void;
  isSelected: boolean;
  selectionSize: number;
};

const PopoverActionsContainer = styled('div')(({ theme }) => ({
  minWidth: `calc(240px + ${theme.spacing(4)})`,
}));

export const Thumbnail: FC<ThumbnailProps> = ({
  scene,
  isCurrent,
  selectionSize,
  isSelected,
  onSelect,
  canReorder,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, node } = useSortable({
    id: scene.id,
    disabled: !canReorder,
  });

  const draggableStyles = useDraggableStyles({ isDragging, transform, transition });

  const isAllowedToEditProject = useTeamPermissionStatus({ has: 'ProjectUpdate' }) === 'ALLOWED';

  const handleProps = useMemo(() => ({ ...attributes, ...listeners }), [attributes, listeners]);
  const popoverActions = usePopoverActions(scene);

  useScrollToThumbnail(node.current, isCurrent);
  useKeypress(useCopySceneToClipboard(scene.id), '$mod+c', { element: node.current ?? undefined });

  const onContextMenu: MouseEventHandler<HTMLElement> = useCallback(
    (event) => {
      // Not the most elegant approach, but there is no good way for us to
      // programmatically open the popover menu from here, so we go through the
      // DOM which is not React-y, but works fine.
      event.preventDefault();
      document.querySelector<HTMLButtonElement>(`#scene-actions-button-${scene.id}`)?.click();
    },
    [scene.id]
  );

  const hasErrors = useMemo(
    () => scene.sceneAssets.some((sceneAsset) => getAssetFromSceneAsset(sceneAsset)?.status === 'Error'),
    [scene.sceneAssets]
  );

  const shouldRenderPopoverMenu = isAllowedToEditProject && popoverActions.length > 0;
  const ActionsPopoverTrigger = useMemo(
    () =>
      withPopoverMenu(PopoverMenuTriggerIcon, {
        children: <PopoverActionsContainer>{popoverActions}</PopoverActionsContainer>,
        transformOriginX: 'left',
        anchorOriginX: 'left',
        offsetY: 4,
      }),
    [popoverActions]
  );

  return (
    <ThumbnailListItemWrapper
      ref={setNodeRef}
      onContextMenu={onContextMenu}
      className="editorThumbnail"
      id={`thumbnail-${scene.id}`}
      style={draggableStyles}
      dragCount={isDragging && isSelected ? selectionSize : undefined}
      aria-selected={isSelected}
      aria-current={isCurrent}
      aria-invalid={hasErrors}
    >
      <ThumbnailContainer
        dragCount={isDragging && isSelected ? selectionSize : undefined}
        isCurrent={isCurrent}
        isSelected={isSelected}
        hasErrors={hasErrors}
        isExcluded={scene.excluded}
      >
        <DraggableEditorThumbnail scene={scene} data-no-dnd="true" />

        <DraggableEditorThumbnailButton
          {...handleProps}
          onClick={(event) => {
            onSelect?.(event);
          }}
          // @dnd-kit uses event.preventDefault() in the onKeyDown event handler
          // they pass, which prevents the onClick event handler from firing
          onKeyDown={(event) => {
            if (event.key === 'Space' || event.key === 'Enter') {
              onSelect?.(event);
            }
            listeners?.onKeyDown?.(event);
          }}
          className="editorThumbnail-button"
          data-testid="select-scene-button"
        >
          <VisuallyHidden i18nParams={{ title: scene.type }}>i18n.projectEdit.scene.edit.button</VisuallyHidden>
        </DraggableEditorThumbnailButton>

        <ActionsContainer>
          {shouldRenderPopoverMenu && (
            <ActionsPopoverTrigger
              backgroundColor="blurred"
              iconColor="white"
              hoverColor="white"
              data-testid="scene-actions-button"
              id={`scene-actions-button-${scene.id}`}
            />
          )}
        </ActionsContainer>
      </ThumbnailContainer>
    </ThumbnailListItemWrapper>
  );
};

import { styled } from '@mui/material';
import type { FC } from 'react';
import { SpinningIcon } from '../../assets/icons/SpinningIcon';
import { Text } from '../../typography/Text';

const Container = styled('p')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  margin: 0,
  padding: theme.spacing(1.5, 3, 1.5, 2),
  color: theme.palette.brand.white,
  backgroundColor: theme.palette.brand.carbon_alpha50,
  backdropFilter: 'blur(13px)',
  borderRadius: theme.shape.borderRadius,
  whiteSpace: 'nowrap',
}));

const TRANSLATIONS: Record<ProcessingType, string> = {
  Transcoding: 'i18n.global.processingIndicator.transcoding',
  EnhanceAudio: 'i18n.global.processingIndicator.enhanceAudio',
  VirtualBackground: 'i18n.global.processingIndicator.virtualBackground',
};

type ProcessingType = 'Transcoding' | 'EnhanceAudio' | 'VirtualBackground';

export type ProcessingIndicatorProps = { type: ProcessingType };

export const ProcessingIndicator: FC<ProcessingIndicatorProps> = ({ type, ...rest }) => (
  <Container {...rest}>
    <SpinningIcon type="LoadingIcon" speed="fast" />
    <Text>{TRANSLATIONS[type]}</Text>
  </Container>
);
